import React from 'react';

const SuccessAlert = (props) => {
    return(
        // if(this.state.submissionSuccess){
        <div>
            <div className="alert alert-success">
                <p>{props.message}</p>
            </div>
        </div>
        // }
    );
}

export default SuccessAlert;