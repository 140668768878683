import React, {Component} from 'react';
import axios from "axios";
import {Redirect, Link} from "react-router-dom";
// import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
// import OpenInBrowser from '@material-ui/icons/OpenInBrowser';
import Typography from '@material-ui/core/Typography';
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import Snackbar from '@material-ui/core/Snackbar';
import Preloader from '../Partials/Preloader';
import Avatar from '@material-ui/core/Avatar';


const theme = createMuiTheme();

const cardStyles = {
    card: {
        maxWidth: 345,
    },
    media: {
        height: 250,
    },
    avatar: {
        width: 250,
        height: 250,
    }
};



class Users extends Component {

    constructor (props){
        super(props);
        this.state = {
            users:[],
            auth:true,
            items_per_page:12,
            total_items:0,
            offset: 0,
            rendered: false,
            snackbar: false,
            token:sessionStorage.getItem('access-token'),
        };
    }


    handleClick(offset) {
        this.setState({ offset });
        var self = this;
        //console.log(e/self.state.limits);
        self.setState({
            rendered: false,
        });
        axios({
            url: 'http://localhost:8000/api/admin/users?pg='+((offset/self.state.items_per_page)+1),
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            self.setState({
                users:response.data.data.users,
                total_items:response.data.data.total_items,
                items_per_page:response.data.data.items_per_page,
                rendered: true,
            });
            console.log('updated');
        }).catch(function (error) {
            console.log(error.response.status);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
                console.log(self.state);
            }
        });
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackbar: false });
    };

    deleteItem = (itemId, index) => {
        var self = this;
        //console.log(e/self.state.limits);
        const allUsers = self.state.users;
        
        axios({
            url: '/users/delete/'+itemId,
            method: 'delete',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            if(response.status === 200){
                allUsers.splice(index,1);
                self.setState({
                    users:allUsers,
                });

                self.setState({
                    snackbar:true,
                });
            }
        }).catch(function (error) {
            console.log(error.response);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
            }
        });
    }
    
    componentWillMount () {
        var self = this;
        axios({
            url: '/users?pg=1',
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            self.setState({
                users:response.data.data.users,
                total_items:response.data.data.total_items,
                limits:response.data.data.limits,
                rendered: true,
            });
            console.log(response.data.data);
        }).catch(function (error) {
            console.log(error.response.status);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
                console.log(self.state);
            }
        });
    }

    render() {
        if(this.state.auth){
            return(
                <div className="row mt-5">

                    <Typography gutterBottom variant="h3" component="h2">
                        Users
                    </Typography>


                    <div className={this.state.rendered ? 'd-none' : 'col-12 text-center'}>
                        <Preloader/>
                    </div>
                    <div className={this.state.rendered ? 'col-12' : 'd-none'}>

                        <Fab color="primary" aria-label="Add" className="mb-3" component={Link} to={'users/add'} >
                            <Icon>person_add_icon</Icon>
                        </Fab>
                    
                        <div className="row">
                            {this.state.users.map((item, key) =>
                                <Card className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 p-1 text-center" key={key}>
                                    <Avatar src="https://loremflickr.com/320/240/girl?lock=30976" className={`${cardStyles.avatar} d-flex m-auto`} style={{width:120,height:120}} />
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="h6">
                                                {item.name} | ({item.staff_id})
                                            </Typography>
                                        {/* <Typography component="p">
                                            Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                                            across all continents except Antarctica
                                        </Typography> */}
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions className='text-center'>
                                        <Fab className='m-auto' size="small" color="secondary" aria-label="Edit" component={Link} to={`/users/edit/${item.id}`}>
                                            <Icon>edit_icon</Icon>
                                        </Fab>
                                        <Fab className='m-auto' size="small" color="secondary" aria-label="Delete" onClick={()=>this.deleteItem(item.id,key)}>
                                            <Icon>delete_icon</Icon>
                                        </Fab>
                                        <Fab className='m-auto' size="small" color="primary" aria-label="View" component={Link} to={`/users/view/${item.id}`}>
                                            <Icon>open_in_browser</Icon>
                                        </Fab>
                                    </CardActions>
                                </Card>
                            )}


                            <div className="row">
                                <div className="col-12">
                                    <MuiThemeProvider theme={theme}>
                                        <CssBaseline />
                                        <Pagination
                                        limit={this.state.items_per_page}
                                        offset={this.state.offset}
                                        total={this.state.total_items}
                                        onClick={(e, offset) => this.handleClick(offset)}
                                        />
                                    </MuiThemeProvider>
                                </div>
                            </div>


                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                open={this.state.snackbar}
                                autoHideDuration={3000}
                                onClose={this.handleClose}
                                ContentProps={{
                                    'aria-describedby': 'message-id',
                                }}
                                message={<span id="message-id">Item Deleted</span>}
                                action={[
                                    // <Button key="undo" color="secondary" size="small" onClick={this.handleClose}>
                                    // UNDO
                                    // </Button>,
                                    <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    onClick={this.handleClose}
                                    >
                                    </IconButton>,
                                ]}
                                />
                        </div>

                    </div>

                </div>
            );
        }else{
            return(
                <Redirect to={'/login'}></Redirect>
            );
        }
        
    }
}

export default Users;