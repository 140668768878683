import React, {Component} from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
// import Button from '@material-ui/core/Button';

import Preloader from '../Partials/Preloader';


class Zones extends Component {

    constructor (props){
       super(props);
       this.state = {
            zones:[],
            auth:true,
            rendered: false,
            snackbar: false,
            token:sessionStorage.getItem('access-token'),
        };
    }

    componentWillMount () {
        var self = this;
        axios({
            url: '/zones',
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            self.setState({
                zones:response.data.data.zones,
                rendered: true,
            });
            // console.log(response.data.data);
        }).catch(function (error) {
            // console.log(error.response.status);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
                // console.log(self.state);
            }
        });
    }

    deleteItem = (itemId, index) => {
        var self = this;
        //console.log(e/self.state.limits);
        const allZones = self.state.zones;
        
        axios({
            url: '/zones/delete/'+itemId,
            method: 'delete',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            if(response.status === 200){
                allZones.splice(index,1);
                self.setState({
                    zones:allZones,
                });

                self.setState({
                    snackbar:true,
                });
            }
        }).catch(function (error) {
            console.log(error.response);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
            }
        });
    }

    render(){
        return(
            <div>
                <Typography gutterBottom variant="h3" component="h2">
                    Zones List
                </Typography>

                <div className={this.state.rendered ? 'd-none' : 'col-12 text-center'}>
                    <Preloader/>
                </div>

                <div className={this.state.rendered ? 'col-12' : 'd-none'}>
                    <Fab color="primary" aria-label="Add" className="mb-3" component={Link} to={'zones/add'} >
                        <Icon>add_icon</Icon>
                    </Fab>

                    <div className='row'>
                        {this.state.zones.map((item, key) =>
                            <div  className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 p-1 text-center" key={key}>
                                <Card>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="h6">
                                                {item.zone_name}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions className='text-center'>
                                        <Fab className='m-auto' size="small" color="secondary" aria-label="Edit" component={Link} to={`/zones/edit/${item.id}`}>
                                            <Icon>edit_icon</Icon>
                                        </Fab>
                                        <Fab className='m-auto' size="small" color="secondary" aria-label="Delete" onClick={()=>this.deleteItem(item.id,key)}>
                                            <Icon>delete_icon</Icon>
                                        </Fab>
                                    </CardActions>
                                </Card>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Zones;