import React, {Component} from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';

import ErrorAlert from '../Partials/FormSubmissionErrorAlert';
import SuccessAlert from '../Partials/FormSubmissionSuccessAlert';

class EditZone extends Component {

    state = {
        token: sessionStorage.getItem('access-token'),
        formdata: {
            id:'',
            zone_name:'',
        },
        validationErrors: [],
        erros:false,
        submissionSuccess:false,
        itemId: this.props.match.params.id,
    }

    handleChange = name => event => {
        this.setState({ 
            formdata: {
            ...this.state.formdata,
            [name]:event.target.value
            }
        });
        // console.log(this.state.formdata);
    };


    submitForm = () => {
        // console.log(this.state);
    
        var self = this;
    
            var headers = {
                'Accept': 'application/json',
                'Authorization': self.state.token,
            };
    
            var postData = self.state.formdata;
    
            axios.post('/zones/edit',postData,{"headers":headers})
              .then(function (response) {
                  self.setState({
                    apiSuccess:true,
                    itemId:response.data.zone_id,
                    submissionSuccess:true,
                  });
                  // console.log(response.data);
              })
              .catch(function (error) {
                  self.setState({
                    error:true,
                  });
                  if(error.response.data.type){
                      self.setState({
                        validationErrors:Object.values(error.response.data.errors)
                      });
                  }
              });
    
      }


    componentDidMount(){
        var self = this;
        axios({
            url:`/zones/view/${self.state.itemId}`,
            method:'get',
            headers: {
                'Content-Type':'multipart/form-data',
                'Accept':'application/json',
                'Authorization':self.state.token,
            }
        }).then(function(response) {
            //console.log(response.data.data.zone);
            self.setState({
                formdata: {
                    id:response.data.data.zone.id,
                    zone_name:response.data.data.zone.zone_name,
                }
            });
        }).catch(function(error){
            self.setState({
                error:true,
            });
            if(error.response.data.type){
                self.setState({
                validationErros:Object.values(error.response.data.errors)
                });
            }
        });
    }
    

    render(){

        let alertView;
        if(this.state.error){
            alertView = <ErrorAlert errors={this.state.validationErrors}/>;
        }
        if(this.state.submissionSuccess){
            alertView = <SuccessAlert message="Zone Updated Successfully"/>;
        }

        return(
            <div>
                <div>
                    <form noValidate autoComplete="off">

                        <div className="row">
                        <div className="col-12">
                            <h2>Edit Zone</h2>
                        </div>
                        </div>

                        {alertView}

                        <div className="row">
                            <TextField
                                id="zonename"
                                label="Zone Name"
                                className='col-12'
                                value={this.state.formdata.zone_name}
                                onChange={this.handleChange('zone_name')}
                                margin="normal"
                            />
                        </div>

                        <Button variant="contained" size="small" className="mt-5 float-right" onClick={this.submitForm} >
                            <SaveIcon/>
                            Save
                        </Button>
                    </form>
                </div>
            </div>
        );
    }
}

export default EditZone;