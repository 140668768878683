import React, {Component} from 'react';
import axios from "axios";
// import {Redirect,Route, Link} from "react-router-dom";
// import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
// import AddIcon from '@material-ui/icons/Add';
// import Fab from '@material-ui/core/Fab';
// import Icon from '@material-ui/core/Icon';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


import LocationZoneModal from '../Locations/LocationZoneModal';

class ViewLocation extends Component{

    constructor(props){
        super(props);
        this.state = {
            token:sessionStorage.getItem('access-token'),
            location:[],
            relatedZones:[],
        };
    }

    linkZoneCallBack=(zones)=>{
        this.setState({
            relatedZones:zones
        });
    }

    unlinkZone = (index,id) => {
        var self = this;

        var array = [...self.state.relatedZones];
        array.splice(index, 1);
        this.setState({relatedZones: array});

        axios({
            url: '/location-zone/delete/'+(id),
            method: 'delete',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function(response){
            //console.log(response.data);
        }).catch(function(error){
            console.log(error.response.data);
        });
    }
  
    componentDidMount() {
        var self = this;
        axios({
            url: '/locations/view/'+(self.props.match.params.id),
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function(response){
            self.setState({
                location:response.data.data.location,
                // availableZones:response.data.data.available_zones,
                relatedZones:response.data.data.related_zones,
            });
            console.log(response.data.data.related_zones);
        }).catch(function(error){
            console.log(error.response.data);
        });
    }

    render() {
        return(
            <Paper className="p-5">
                <h1>{this.state.location.name}</h1>
                <address>{this.state.location.address}</address>
                <a href="tel:{this.state.location.phone}">{this.state.location.phone}</a>
                <br/>
                <a href="mailto:{this.state.location.email}">{this.state.location.email}</a>
                <hr/>
                <div className='row'>

                    <div className={'col-12 col-sm-12'}>
                        <h4>Assigned Zones</h4>
                        <div className='row mb-5'>
                            {this.state.relatedZones.map((item, key)=>
                                <Card key={key} className='col-12 col-sm-4'>
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            {item.name}
                                        </Typography>
                                        <Typography component="p">
                                            Swiper: {item.swiper_id}
                                        </Typography>
                                        <Typography component="p">
                                            Price: {item.price}
                                        </Typography>
                                        <Typography component="p">
                                            Capacity: {item.capacity}
                                        </Typography>
                                        <Typography component="p">
                                            Session Duration: {item.session_duration}
                                        </Typography>
                                        <Typography component="p">
                                            Minimum Height: {item.min_height}
                                        </Typography>
                                        <Typography component="p">
                                            Medium Height: {item.mid_height}
                                        </Typography>
                                        <Typography component="p">
                                            Maximum Height: {item.max_height}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <IconButton aria-label="Delete" onClick={()=>this.unlinkZone(key,item.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            )}
                        </div>
                    </div>

                </div>

                <LocationZoneModal location={this.props.match.params.id} linkedZone={this.linkZoneCallBack} />

            </Paper>
        );
    }
}

export default ViewLocation;