import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Login from "../Layouts/Login";
import Master from "../Layouts/Master";

class Routing extends Component {

    render() {
        return(
            <Switch>
                <Route path='/' exact component={Login} />
                <Route path='/login' exact component={Login} />
                <Route path='/' component={Master} />
            </Switch>
        );
    }
}

export default Routing;