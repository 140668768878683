import React, {Component} from 'react';
import axios from "axios";
import {Redirect, Link} from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

class Locations extends  Component {

    constructor(props){
        super(props);
        this.state = {
            locations:[],
            auth:true,
            token:sessionStorage.getItem('access-token'),
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.auth){
            sessionStorage.removeItem('access-token');
            console.log('Not Loged In');
        }
    }

    componentDidMount() {
        var self = this;
        axios({
            url: '/locations',
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            self.setState({
                locations:response.data.data.locations
            });
            // console.log(self.state.locations.length);
        })
            .catch(function (error) {
                console.log(error.response.status);
                if(error.response.status === 401){
                    self.setState({
                        auth:false
                    });
                    // console.log(self.state);
                }
            });
    }

    render() {
        if(this.state.auth) {
            return (
                <div className='container'>

                    <div className="row">
                        <div className="col-12">
                            <Typography gutterBottom variant="h3" component="h2">
                                Locations
                            </Typography>

                            <Fab color="primary" aria-label="Add" className="mb-3" component={Link} to={'/locations/add'} >
                                <Icon>add_icon</Icon>
                            </Fab>
                            <div className="row">
                            
                                {this.state.locations.map((item, key) =>

                                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 p-1 text-center" key={key}>
                                        <Card>
                                            <CardActionArea>
                                                
                                                <CardContent>
                                                    <Typography gutterBottom variant="h6" component="h6">
                                                        {item.name}
                                                    </Typography>
                                                    <Typography gutterBottom variant="p" component="p">
                                                        {item.address}
                                                    </Typography>
                                                {/* <Typography component="p">
                                                    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                                                    across all continents except Antarctica
                                                </Typography> */}
                                                </CardContent>
                                            </CardActionArea>
                                            <CardActions>
                                                <Fab className='m-auto' size="small" color="secondary" aria-label="Edit" component={Link} to={`/locations/edit/${item.id}`}>
                                                    <Icon>edit_icon</Icon>
                                                </Fab>
                                                {/* <Fab size="small" color="secondary" aria-label="Delete" onClick={()=>this.deleteItem(item.id,key)}>
                                                    <Icon>delete_icon</Icon>
                                                </Fab> */}
                                                <Fab className='m-auto' size="small" color="primary" aria-label="View" component={Link} to={`/locations/view/${item.id}`}>
                                                    <Icon>open_in_browser</Icon>
                                                </Fab>
                                            </CardActions>
                                        </Card>
                                    </div>
                                    
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }else{
            return(
                <Redirect to={'/login'}></Redirect>
            );
        }
    }
}

export default Locations;