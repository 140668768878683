import React ,{Component} from 'react';
import axios from "axios";
// import {Route, Switch,Redirect,Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
// import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


class LinkDevices extends Component {

    constructor (props){
        super(props);
        this.state = {
            device:[],
            auth:true,
            otp:null,
            locations:[],
            selectedLocation:null,
            rendered:false,
            snackbar: false,
            token:sessionStorage.getItem('access-token'),
        };
    }

    generateOTP = () => {
        var self = this;
        axios({
            url: `/devices/generate-otp/${self.props.match.params.id}`,
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            self.setState({
                otp:response.data.data.otp,
            });
            // console.log(response.data.data.device);
        }).catch(function (error) {
            // console.log(error.response.status);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
                // console.log(self.state);
            }
        });
    }

    handleChange = event => {
        this.setState({ selectedLocation: event.target.value });
    };

    linkLocation = () => {
        var self = this;

        var headers = {
            'Accept': 'application/json',
            'Authorization': self.state.token,
        };

        var postData = {
            'location_id':self.state.selectedLocation,
        };

        axios.post(`/devices/link-location/${self.props.match.params.id}`,postData,{"headers":headers})
          .then(function (response) {
            self.setState({
                device:response.data.data.device,
            });
              // console.log(response.data);
          }).catch(function (error) {
            // console.log(error.response.status);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
                // console.log(self.state);
            }
        });
    }

    componentWillMount () {
        var self = this;
        axios({
            url: `/devices/view/${self.props.match.params.id}`,
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            self.setState({
                device:response.data.data.device,
                rendered: true,
            });
            console.log(response.data.data.device);
        }).catch(function (error) {
            console.log(error.response.status);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
                console.log(self.state);
            }
        });

        if(!self.state.device.location_id){
            axios({
                url: `/locations`,
                method: 'get',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': self.state.token,
                },
            }).then(function (response) {
                self.setState({
                    locations:response.data.data.locations,
                });
                // console.log(response.data.data.device);
            }).catch(function (error) {
                // console.log(error.response.status);
                if(error.response.status === 401){
                    self.setState({
                        auth:false
                    });
                    // console.log(self.state);
                }
            }); 
        }


    }


    render(){
        return(
            <div className='mt-5 text-center'>
                <h1>Link Devices</h1>
                <hr/>
                <h5>UID : {this.state.device.uid}</h5>
                {this.state.device.location_id ? 
                    <div>
                        <h5>Location {this.state.device.location}</h5>
                        <h5>Address {this.state.device.address}</h5>
                    </div> 
                : 
                    <form autoComplete="off">
                        <FormControl>
                        <InputLabel htmlFor="demo-controlled-open-select">Location</InputLabel>
                            <Select style={{width:500}}
                                open={this.state.open}
                                onClose={this.handleClose}
                                onOpen={this.handleOpen}
                                value={this.state.age}
                                onChange={this.handleChange}
                                inputProps={{
                                name: 'age',
                                id: 'demo-controlled-open-select',
                                }}
                            >
                                <MenuItem value={this.state.selectedLocation}>
                                <em>{this.selectedLocation}</em>
                                </MenuItem>
                                {this.state.locations.map((item, key) =>
                                    <MenuItem value={item.id}>{item.name}</MenuItem>
                                )}
                            </Select>
                            <Button className="mt-2" key="link" color="primary" size="small" onClick={this.linkLocation}>
                                <Icon>save_icon</Icon>
                                Link With Location
                            </Button>
                        </FormControl>
                    </form>
                }
                

                <Button className="mt-5" key="link" color="secondary" size="small" onClick={this.generateOTP}>
                    <Icon>perm_phone_msg_icon</Icon>
                     Generate OTP
                </Button>

                {this.state.otp ? <h5>OTP: {this.state.otp}</h5> : ''}
            </div>
        )
    }
}

export default LinkDevices;