import React from 'react';
import axios from "axios";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ErrorAlert from '../Partials/FormSubmissionErrorAlert';


export default class FormDialog extends React.Component {
  state = {
    token:sessionStorage.getItem('access-token'),
    open: false,
    availableZones:[],
    addedZone:'',
    formdata : {
        location_id:this.props.location,
        zone_id: '',
        swiper_id: '',
        capacity: '',
        price: '',
        session_duration: '',
        height_mn: '',
        height_md: '',
        height_mx: '',
    },
    validationErrors: [],
    errors:false,
    submissionSuccess:false,
  };

  radioChange = event => {
    this.setState({ 
        formdata:{
            ...this.state.formdata,
            zone_id: event.target.value
        }
     });
  };

  handleChange = name => event => {
    this.setState({
        // [event.target.name]: event.target.value
        formdata: {
            ...this.state.formdata,
            [name]:event.target.value
        }
    });
    // console.log(this.state.formdata);
   };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };


  linkZone = () => {
    //alert(id);
    var self = this;

    var headers = {
        'Accept': 'application/json',
        'Authorization': self.state.token,
    };

    var postData = self.state.formdata;

    axios.post('/location-zone/add',postData,{"headers":headers})
        .then(function (response) {
            // console.log(response.data);
            self.props.linkedZone(response.data.linkedZones);
            self.setState({ 
                open: false,
             });
        })
        .catch(function (error) {
            self.setState({
                errors:true,
                submissionSuccess:false,
                validationErrors:Object.values(error.response.data.errors)
            });
        });
    
    }



    componentDidMount() {
        var self = this;
        axios({
            url: '/zones',
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function(response){
            self.setState({
                availableZones:response.data.data.zones,
            });
            // console.log(response.data.data.zones);
        }).catch(function(error){
            console.log(error.response.data);
        });
    }

  render() {

    let alertView;
    if(this.state.errors){
        alertView = <ErrorAlert errors={this.state.validationErrors}/>;
    }

    return (
      <div>
        <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
          Assign Zones
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Link Zone</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please link the current location with listed zone.
            </DialogContentText>

            {alertView}

            <hr></hr>
            <RadioGroup
                aria-label="zones"
                name="zones"
                className='d-block'
            >
            {this.state.availableZones.map((zone,key)=>
                <FormControlLabel
                    key={key}
                    control={<Radio
                        checked={this.state.formdata.zone_id === `${zone.id}`}
                        onChange={this.radioChange}
                        value={zone.id}
                        name={'radio-'+zone.id}
                        aria-label={zone.zone_name}
                    />}
                    label={zone.zone_name}
                    labelPlacement="start"
                />
            )}
            </RadioGroup>
            <hr></hr>
            <TextField
              autoFocus
              margin="dense"
              id="swiper_id"
              label="Swiper ID"
              type="text"
              onChange={this.handleChange('swiper_id')}
              fullWidth
            />
            <TextField
              margin="dense"
              id="price"
              label="Price"
              type="text"
              onChange={this.handleChange('price')}
              fullWidth
            />
            <TextField
              margin="dense"
              id="capacity"
              label="Capcity"
              type="text"
              onChange={this.handleChange('capacity')}
              fullWidth
            />
            <TextField
              margin="dense"
              id="session_duration"
              label="Session Duration"
              type="text"
              onChange={this.handleChange('session_duration')}
              fullWidth
            />
            <TextField
              margin="dense"
              id="height_mn"
              label="Minimum Height"
              type="text"
              onChange={this.handleChange('height_mn')}
              fullWidth
            />
            <TextField
              margin="dense"
              id="height_md"
              label="Medium Height"
              type="text"
              onChange={this.handleChange('height_md')}
              fullWidth
            />
            <TextField
              margin="dense"
              id="height_mx"
              label="Maximum Height"
              type="text"
              onChange={this.handleChange('height_mx')}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button color="primary" onClick={()=>this.linkZone()}>
              Assign
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
