import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

class NationalityDropDown extends React.Component {
  state = {
    token:sessionStorage.getItem('access-token'),
    nationalities:[],
    country:this.props.slectedCountry,
    open: false,
  };

  handleChange = event => {
    //   console.log(event.target);
    this.setState({ 
        [event.target.name]: event.target.value,
        country:event.target.value,
     });
     this.props.nationalityCallBack(event.target.value);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };


  componentDidMount() {
    let self = this;
    axios({
        url:'/nationalities',
        method:'get',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Authorization': self.state.token,
        },
    }).then(function(response){
        self.setState({
            nationalities:response.data
        });
        // console.log(response.data[0]);
    }).catch(function(error){
        console.log(error);
    });
}

  render() {

    return (
      <div>
        <FormControl className={'col-12'}>
          <InputLabel htmlFor="demo-controlled-open-select">Country</InputLabel>
          <Select
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={this.state.country}
            onChange={this.handleChange}
            inputProps={{
              name: 'country',
              id: 'controlled-open-select',
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {this.state.nationalities.map((country,key) => 
                <MenuItem key={key} value={country.key}>{country.label}</MenuItem>
            )};
          </Select>
        </FormControl>
      </div>
    );
  }
}

NationalityDropDown.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NationalityDropDown);
