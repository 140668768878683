import React, { Component } from 'react';
import {BrowserRouter} from 'react-router-dom';
import Routing from "./Components/Routing/Routing";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className={"App"}>
          <Routing/>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
