import React, {Component} from 'react';
// import {NavLink} from "react-router-dom";
import axios from 'axios';
import {Redirect} from 'react-router-dom';
// import Fab from '@material-ui/core/Fab';
// import Preloader from '../Partials/Preloader';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

class Dashboard extends Component {
    constructor (props) {
        super(props);
        // console.log(sessionStorage.getItem('access-token'));
        this.state = {
            token:sessionStorage.getItem('access-token'),
            totalUsers:0,
            totalMembers:0,
            totalDevices:0,
            totalCheckins:0,
            auth:true,
        }
    }


    componentDidMount () {
        var self = this;
        axios({
            url: '/dashboard',
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            self.setState({
                totalUsers:response.data.data.totalUsers,
                totalMembers:response.data.data.totalMembers,
                totalDevices:response.data.data.totalDevices,
                totalCheckins:response.data.data.totalCheckins,
                
            });
            console.log(response.data.data);
        }).catch(function (error) {
            // console.log(error.response.status);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
            }
        });
    }

    render() {
            if(!this.state.auth){
                return <Redirect to='/login' />;
            }
            return(
                <div className="container" role="main">
                    <Typography gutterBottom variant="h4" component="h4">
                        Dashboard
                    </Typography>
                    <hr/>
                    <div className='row'>
                        <div className='col-12 col-sm-6 mb-3 p-5 text-center'>
                            <Card>
                                <CardActionArea>
                                    <CardContent>
                                        <Icon>account_circle_icon</Icon>
                                        <Typography gutterBottom variant="h5" component="h5">
                                            Total Users
                                        </Typography>
                                        <Typography gutterBottom variant="p" component="p">
                                            {this.state.totalUsers}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className='col-12 col-sm-6 mb-3 p-5 text-center'>
                            <Card>
                                <CardActionArea>
                                    <CardContent>
                                        <Icon>tablet_mac_icon</Icon>
                                        <Typography gutterBottom variant="h5" component="h5">
                                            Total Devices
                                        </Typography>
                                        <Typography gutterBottom variant="p" component="p">
                                            {this.state.totalDevices}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className='col-12 col-sm-6 mb-3 p-5 text-center'>
                            <Card>
                                <CardActionArea>
                                    <CardContent>
                                        <Icon>group_icon</Icon>
                                        <Typography gutterBottom variant="h5" component="h5">
                                            Total Members
                                        </Typography>
                                        <Typography gutterBottom variant="p" component="p">
                                            {this.state.totalMembers}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                        <div className='col-12 col-sm-6 mb-3 p-5 text-center'>
                            <Card>
                                <CardActionArea>
                                    <CardContent>
                                        <Icon>how_to_reg_icon</Icon>
                                        <Typography gutterBottom variant="h5" component="h5">
                                            Total Checkins
                                        </Typography>
                                        <Typography gutterBottom variant="p" component="p">
                                            {this.state.totalCheckins}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </div>
                    </div>
                </div>
            );
        }
}

export default Dashboard;