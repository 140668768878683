import React, {Component} from 'react';
import axios from 'axios';
import {Redirect} from "react-router-dom";

// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Typography from '@material-ui/core/Typography';
// import Fab from '@material-ui/core/Fab';
// import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';

// import Preloader from '../Partials/Preloader';

class AddZone extends Component {

    constructor (props){
       super(props);
       this.state = {
            formdata: {
                zone_name: null,
            },
            validationErros:[],
            apiSuccess:false,
            error:false,
            auth:true,
            snackbar: false,
            token:sessionStorage.getItem('access-token'),
        };
    }

    handleChange = name => event => {
        this.setState({ 
            formdata: {
            ...this.state.formdata,
            [name]:event.target.value
            }
        });
        // console.log(this.state.formdata);
    };


    submitForm = () => {
        // console.log(this.state);
    
        var self = this;
    
            var headers = {
                'Accept': 'application/json',
                'Authorization': self.state.token,
            };
    
            var postData = self.state.formdata;
    
            axios.post('/zones/add',postData,{"headers":headers})
              .then(function (response) {
                  self.setState({
                    apiSuccess:true,
                    itemId:response.data.zone_id,
                  });
                  // console.log(response.data);
              })
              .catch(function (error) {
                  self.setState({
                    error:true,
                  });
                  if(error.response.data.type){
                      self.setState({
                        validationErros:Object.values(error.response.data.errors)
                      });
                  }
              });
    
      }


    render(){

        let errorView;

        if(this.state.apiSuccess){
            return <Redirect to={`/zones`} />;
        }

        if(this.state.error){
            errorView = <div>
              <div className="alert alert-danger">
                <p>Something wrong</p>
              </div>
              <div>
              {this.state.validationErros.map((item, key) =>
                // <ValidationSnackBar type={'error'} text={"Hellooooo"} key={key}></ValidationSnackBar>
                <div className="badge badge-danger m-1" key={key}>
                  {item[0]}
                </div>
              )}
              </div>
            </div>
          }

        return(
            <div>
                <div>
                    <form noValidate autoComplete="off">

                        <div className="row">
                        <div className="col-12">
                            <h2>Add Zone</h2>
                        </div>
                        </div>

                        {errorView}

                        <div className="row">
                            <TextField
                                id="zonename"
                                label="Zone Name"
                                className='col-12'
                                value={this.state.formdata.zone_name}
                                onChange={this.handleChange('zone_name')}
                                margin="normal"
                            />
                        </div>

                        <Button variant="contained" size="small" className="mt-5 float-right" onClick={this.submitForm} >
                            <SaveIcon/>
                            Save
                        </Button>
                    </form>
                </div>
            </div>
        );
    }

}

export default AddZone;
