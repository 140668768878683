import React, {Component} from 'react';
import axios from "axios";
import {Redirect} from 'react-router-dom';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';



class ViewUser extends Component {

    constructor (props){
        super(props);
        this.state = {
            user:[],
            auth:true,
            token:sessionStorage.getItem('access-token'),
        };
    }

    componentWillMount () {
        var self = this;
        axios({
            url: `/users/view/${self.props.match.params.id}`,
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            self.setState({
                user:response.data.data.user,
            });
            // console.log(response.data.data.user);
        }).catch(function (error) {
            console.log(error.response.status);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
                console.log(self.state);
            }
        });
    }


    render() {

        if(!this.state.auth){
            sessionStorage.removeItem('access-token');
            return(
                <Redirect to={'/login'}></Redirect>
            );
        }

        let userStatus;

        if(!this.state.user.location_id){
            userStatus = <div>
                <span className="badge badge-dark">Offline</span> 
            </div>
        }else{
            userStatus = <div>
                <span className="badge badge-success">Online</span>  
            </div>
        }

        return (
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-12 col-sm-6 m-auto text-center'}>
                        <Typography gutterBottom variant="h4" component="h4">
                            User Details
                        </Typography>
                        <Card>
                            <Avatar alt='$`{item.name}`' src="https://loremflickr.com/320/240/girl?lock=30976" className={`d-flex my-3 mx-auto`} style={{width:120,height:120}} />
                            <CardActionArea>
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {this.state.user.name}
                                </Typography>
                                <Typography component="p">
                                    Email: {this.state.user.email}
                                </Typography>
                                <Typography component="p">
                                    Staff ID: {this.state.user.staff_id}
                                </Typography>
                                <hr></hr>

                                {userStatus}
                                  
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button className='m-auto' size="small" color="primary">
                                    Block
                                </Button>
                                <Button className='m-auto' size="small" color="primary">
                                    Revoke Session
                                </Button>
                            </CardActions>
                        </Card>
                    </div>
                    
                </div>
            </div>
        );
    }
}

export default ViewUser;