import React, {Component} from 'react';
import axios from 'axios';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';


import NationalityDropDown from '../Partials/NationalityDropDown';
import ErrorAlert from '../Partials/FormSubmissionErrorAlert';
import SuccessAlert from '../Partials/FormSubmissionSuccessAlert';

class EditLocation extends Component {

    state = {
        token:sessionStorage.getItem('access-token'),
        formdata: {
            id: this.props.match.params.id,
            embed_location_id:'',
            location_name:'',
            address:'',
            country_id:'',
            lat:'',
            long:'',
            phone:'',
            email:'',
        },
        nationalities:[],
        validationErrors: [],
        errors:false,
        submissionSuccess:false,
        itemId:this.props.match.params.id,
      };

      handleChange = name => event => {
        this.setState({
            // [event.target.name]: event.target.value
            formdata: {
                ...this.state.formdata,
                [name]:event.target.value
            }
        });
    };


    nationalityCallBack=(countryId)=>{
        // console.log(countryId);
        this.setState({
            formdata: {
                ...this.state.formdata,
                country_id: countryId
            }
        });
    }

      componentDidMount () {
        var self = this;
        axios({
            url: `/locations/view/${self.state.itemId}`,
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            self.setState({
                formdata: {
                    ...self.state.formdata,
                    embed_location_id:response.data.data.location.embed_location_id,
                    location_name:response.data.data.location.name,
                    address:response.data.data.location.address,
                    country_id:response.data.data.location.country_id,
                    lat:response.data.data.location.lat,
                    long:response.data.data.location.long,
                    phone:response.data.data.location.phone,
                    email:response.data.data.location.email,
                },
            });
            // console.log(response.data.data.location.embed_location_id);
        }).catch(function (error) {
            // console.log(error.response.status);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
                // console.log(self.state);
            }
        });
    }


    formSubmit = () => {
        var self = this;
        var headers = {
            'Accept': 'application/json',
            'Authorization': self.state.token,
        };

        var postData = self.state.formdata;

        // console.log(postData);

        axios.post('/locations/edit',postData,{"headers":headers})
            .then(function (response) {
                self.setState({
                    submissionSuccess:true,
                });
               self.props.history.replace(`/locations/view/${response.data.location}`);
            })
            .catch(function (error) {
                self.setState({
                    errors:true,
                    submissionSuccess:false,
                    validationErrors:Object.values(error.response.data.errors)
                });
            });
    };

    render() {

        let alertView;
        if(this.state.errors){
            alertView = <ErrorAlert errors={this.state.validationErrors}/>;
        }
        if(this.state.submissionSuccess){
            alertView = <SuccessAlert message="Location added Successfully"/>;
        }


        let nationalityDropDown;
        if(this.state.formdata.country_id){
            nationalityDropDown = <NationalityDropDown slectedCountry={this.state.formdata.country_id} nationalityCallBack = {this.nationalityCallBack}/>;
        }

        return(
            <div className={'container'}>

                <div className='row'>
                    <div className='col-12'>
                        <h1>Edit Location</h1>
                        {alertView}
                    </div>
                </div>
                <hr></hr>
                <div className='row'>
                    <div className='col-12 m-auto'>
                        <Card>
                            <CardContent>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className='col-12'>
                                                <h4>Location Details</h4>
                                            </div>
                                            <div className="col-12 form-group">
                                                <TextField
                                                    id="embed_location_id"
                                                    label="Embed Location ID"
                                                    className='col-12'
                                                    value={this.state.formdata.embed_location_id}
                                                    onChange={this.handleChange('embed_location_id')}
                                                    margin="normal"
                                                />
                                            </div>
                                            <div className="col-12 form-group">
                                                <TextField
                                                    id="location_name"
                                                    label="Location Name"
                                                    className='col-12'
                                                    value={this.state.formdata.location_name}
                                                    onChange={this.handleChange('location_name')}
                                                    margin="normal"
                                                />
                                            </div>
                                            <div className="col-12 form-group">
                                                <TextField
                                                    id="address"
                                                    label="Address"
                                                    className='col-12'
                                                    value={this.state.formdata.address}
                                                    onChange={this.handleChange('address')}
                                                    margin="normal"
                                                />
                                            </div>
                                            <div className="col-12 form-group">
                                                {nationalityDropDown}
                                            </div>
                                            <div className="col-12 form-group">
                                                <TextField
                                                    id="lat"
                                                    label="Latitude"
                                                    className='col-12'
                                                    value={this.state.formdata.lat}
                                                    onChange={this.handleChange('lat')}
                                                    margin="normal"
                                                />
                                            </div>
                                            <div className="col-12 form-group">
                                                <TextField
                                                    id="long"
                                                    label="Longitude"
                                                    className='col-12'
                                                    value={this.state.formdata.long}
                                                    onChange={this.handleChange('long')}
                                                    margin="normal"
                                                />
                                            </div>
                                            <div className="col-12 form-group">
                                                <TextField
                                                    id="phone"
                                                    label="Phone"
                                                    className='col-12'
                                                    value={this.state.formdata.phone}
                                                    onChange={this.handleChange('phone')}
                                                    margin="normal"
                                                />
                                            </div>
                                            <div className="col-12 form-group">
                                                <TextField
                                                    id="email"
                                                    label="Email"
                                                    className='col-12'
                                                    value={this.state.formdata.email}
                                                    onChange={this.handleChange('email')}
                                                    margin="normal"
                                                />
                                            </div>
                                        </div>
                                    </div>    
                                </div>

                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <Button variant="contained" color="primary" className={'btn-block'} onClick={this.formSubmit}>
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>  
            </div>
        );
    }
}

export default EditLocation;