import React, {Component} from 'react';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

// import ValidationSnackBar from '../Partials/ValidationSnackBar'

class EditUser extends Component {
  state = {
    token:sessionStorage.getItem('access-token'),
    formdata: {
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      staff_id: '',
    },
    validationErros:[],
    apiSuccess:false,
    error:false,
    itemId:this.props.match.params.id,
  };

  handleChange = name => event => {
    this.setState({ 
      formdata: {
        ...this.state.formdata,
        [name]:event.target.value
      }
    });
    // console.log(this.state.formdata);
  };

    componentDidMount () {
        var self = this;
        axios({
            url: `/users/view/${self.state.itemId}`,
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            self.setState({
                formdata: {
                    first_name:response.data.data.user.first_name,
                    last_name:response.data.data.user.last_name,
                    email:response.data.data.user.email,
                    mobile:response.data.data.user.mobile,
                    staff_id:response.data.data.user.staff_id,
                }
            });
            // console.log(response.data.data.user.first_name);
        }).catch(function (error) {
            console.log(error.response.status);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
                console.log(self.state);
            }
        });
    }


  submitForm = () => {
    // console.log(this.state);

    var self = this;

        var headers = {
            'Accept': 'application/json',
            'Authorization': self.state.token,
        };

        var postData = self.state.formdata;

        axios.post(`/users/edit/${self.state.itemId}`,postData,{"headers":headers})
          .then(function (response) {
              self.setState({
                apiSuccess:true,
              });
              // console.log(response.data);
          })
          .catch(function (error) {
              self.setState({
                error:true,
              });
              if(error.response.data.type){
                  self.setState({
                    validationErros:Object.values(error.response.data.errors)
                  });
              }
              
              // console.log(error.response.data);
              // self.setState({
              //   snacbarOpen : true
              // });
          });

  }

  render() {
    let errorView;

    if(this.state.apiSuccess){
      return <Redirect to={`/users/view/${this.state.itemId}`} />;
    }

    if(this.state.error){
      errorView = <div>
        <div className="alert alert-danger">
          <p>Something wrong</p>
        </div>
        <div>
        {this.state.validationErros.map((item, key) =>
          // <ValidationSnackBar type={'error'} text={"Hellooooo"} key={key}></ValidationSnackBar>
          <div className="badge badge-danger m-1" key={key}>
            {item[0]}
          </div>
        )}
        </div>
      </div>
    }

    return (
      <div className='container'>

        <form noValidate autoComplete="off">

          <div className="row">
            <div className="col-12">
              <h2>Edit User</h2>
            </div>
          </div>

          {errorView}

          <div className="row">

            <TextField
              id="firstname"
              label="First Name"
              className='col-12 col-sm-6'
              value={this.state.formdata.first_name}
              onChange={this.handleChange('first_name')}
              margin="normal"
            />

            <TextField
              id="lastname"
              label="Last Name"
              className='col-12 col-sm-6'
              value={this.state.formdata.last_name}
              onChange={this.handleChange('last_name')}
              margin="normal"
            />

            <TextField
              id="email"
              label="Email"
              className='col-12 col-sm-4'
              value={this.state.formdata.email}
              onChange={this.handleChange('email')}
              margin="normal"
            />

            <TextField
              id="mobile"
              label="Mobile"
              className='col-12 col-sm-4'
              value={this.state.formdata.mobile}
              onChange={this.handleChange('mobile')}
              margin="normal"
            />

            <TextField
              id="staffid"
              label="Staff ID"
              className='col-12 col-sm-4'
              value={this.state.formdata.staff_id}
              onChange={this.handleChange('staff_id')}
              margin="normal"
            />

          </div>

        <Button variant="contained" size="small" className="mt-5 float-right" onClick={this.submitForm} >
            <SaveIcon className="" />
            Update
        </Button>
        </form>
      </div>
    );
  }
}

export default EditUser;