import React, {Component} from 'react';
import axios from "axios";
import {Redirect,Link} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
// import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Preloader from '../Partials/Preloader';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';


class Devices extends Component {

    constructor (props){
        super(props);
        this.state = {
            devices:[],
            auth:true,
            rendered:false,
            snackbar: false,
            token:sessionStorage.getItem('access-token'),
        };
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackbar: false });
    };

    deleteItem = (itemId, index) => {
        var self = this;
        //console.log(e/self.state.limits);
        const allDevices = self.state.devices;
        
        axios({
            url: '/devices/delete/'+itemId,
            method: 'delete',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            if(response.status === 200){
                allDevices.splice(index,1);
                self.setState({
                    users:allDevices,
                });

                self.setState({
                    snackbar:true,
                });
            }
        }).catch(function (error) {
            // console.log(error.response);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
            }
        });
    }

    componentDidMount () {
        var self = this;
        axios({
            url: '/devices',
            method: 'get',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': self.state.token,
            },
        }).then(function (response) {
            self.setState({
                devices:response.data.data.devices,
                rendered:true,
            });
            // console.log(response.data.data);
        }).catch(function (error) {
            // console.log(error.response.status);
            if(error.response.status === 401){
                self.setState({
                    auth:false
                });
            }
        });
    }

    render(){


        if(!this.state.auth){
            sessionStorage.removeItem('access-token');
            return(
                <Redirect to={'/login'}></Redirect>
            );
        }

        return(
            <div className="row mt-5">
            <Typography gutterBottom variant="h3" component="h2">
                Devices
            </Typography>

        
            <div className={this.state.rendered ? 'd-none' : 'col-12 text-center'}>
                <Preloader/>
            </div>
            <div className={this.state.rendered ? 'col-12' : 'd-none'}>

                <Fab color="primary" aria-label="Add" className="mb-3" component={Link} to={'users/add'} >
                    <Icon>add_icon</Icon>
                </Fab>

                <div className="row">
                    {this.state.devices.map((item, key) =>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 p-1 text-center" key={key}>
                            <Card>
                                <CardActionArea>
                                    {item.location_id ? 
                                    <Fab className='m-auto' color="primary" aria-label="Add" size="small" component={Link} to={'users/add'} >
                                        <Icon>phonelink_lock</Icon>
                                    </Fab> : 
                                    <Fab color="secondary" aria-label="Add" size="small" className="mb-3" component={Link} to={'users/add'} >
                                        <Icon>phonelink_erase</Icon>
                                    </Fab>}
                                    
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                        UID : {item.uid}
                                        </Typography>
                                        <Typography component="p">
                                            {item.location}
                                        </Typography>
                                        <Typography component="p">
                                            {item.address}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Fab size="small" color="primary" aria-label="Edit" component={Link} to={`/devices/link/${item.id}`}>
                                    <Icon>open_in_browser</Icon>
                                    </Fab>
                                    <Fab size="small" color="secondary" aria-label="Delete" onClick={()=>this.deleteItem(item.id,key)}>
                                        <Icon>delete_icon</Icon>
                                    </Fab>
                                </CardActions>
                            </Card>
                        </div>
                    )}
                
                </div>

            </div>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={this.state.snackbar}
                autoHideDuration={3000}
                onClose={this.handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">Item Deleted</span>}
                action={[
                    // <Button key="undo" color="secondary" size="small" onClick={this.handleClose}>
                    // UNDO
                    // </Button>,
                    <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={this.handleClose}
                    >
                    </IconButton>,
                ]}
                >
             </Snackbar>

        </div>
        );
    }
}

export default Devices;