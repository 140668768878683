import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import axios from "axios";
// import Footer from "../Pages/Partials/Footer";
// import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import withStyles from '@material-ui/core/styles/withStyles';
import './Login.css'


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username:'',
            password:'',
            isLoggedIn:false,
        }
    }

    inputChange = (event) => {
        if(event.target.id === 'username'){
            this.setState({
                username:event.target.value
            });
        }else{
            this.setState({
                password:event.target.value
            });
        }
    };

    renderRedirect = () => {
        if(this.state.isLoggedIn){
            return <Redirect to='/dashboard' />;
        }
    };

    formSubmit = (event) => {
        event.preventDefault();
        var self = this;
        var payload={
            "username":this.state.username,
            "password":this.state.password
        };
        console.log(payload);
        // console.log(self.state.username);
        // axios({
        //     url: '/login',
        //     method: 'post',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'multipart/form-data',
        //     },
        //     parameter:{
        //         username: self.state.username,
        //         password: self.state.password,
        //     }
        //
        //   })
        axios.post('/login', payload)
            .then(function (response) {
            // console.log(response.data);
            if(response.data.token){
                sessionStorage.setItem('access-token', response.data.token);
                self.setState({
                    isLoggedIn:true
                });
            }else{
                console.log('Error..!');
            }
        })
        .catch(function (error) {
            console.log(error.response.data.errors);
        });
    };

    render () {
        return (
            <main className="main">
            {this.renderRedirect()}
              <CssBaseline />
              <Paper className="paper">
                <Avatar className="avatar">
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <form className="form">
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="email">Email Address</InputLabel>
                    <Input id="username" name="username" autoComplete="email" onChange={this.inputChange} autoFocus />
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <Input name="password" type="password" id="password" onChange={this.inputChange} autoComplete="current-password" />
                  </FormControl>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={this.formSubmit}
                    color="primary"
                    className="submit"
                  >
                    Sign in
                  </Button>
                </form>
              </Paper>
            </main>
          );
    }
}

export default Login;