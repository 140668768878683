import React, {Component} from 'react';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

// import ValidationSnackBar from '../Partials/ValidationSnackBar'

class AddUser extends Component {
  state = {
    token:sessionStorage.getItem('access-token'),
    formdata: {
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      staff_id: '',
      password: '',
      confirm_password: '',
    },
    validationErros:[],
    apiSuccess:false,
    error:false,
    itemId:false,
    // multiline: 'Controlled',
    // currency: 'EUR',
  };

  handleChange = name => event => {
    this.setState({ 
      formdata: {
        ...this.state.formdata,
        [name]:event.target.value
      }
    });
    // console.log(this.state.formdata);
  };


  submitForm = () => {
    // console.log(this.state);

    var self = this;

        var headers = {
            'Accept': 'application/json',
            'Authorization': self.state.token,
        };

        var postData = self.state.formdata;

        axios.post('/users/add',postData,{"headers":headers})
          .then(function (response) {
              self.setState({
                apiSuccess:true,
                itemId:response.data.user_id,
              });
              // console.log(response.data);
          })
          .catch(function (error) {
              self.setState({
                error:true,
              });
              if(error.response.data.type){
                  self.setState({
                    validationErros:Object.values(error.response.data.errors)
                  });
              }
              
              // console.log(error.response.data);
              // self.setState({
              //   snacbarOpen : true
              // });
          });

  }

  render() {
    let errorView;

    if(this.state.apiSuccess){
      return <Redirect to={`/users/view/${this.state.itemId}`} />;
    }

    if(this.state.error){
      errorView = <div>
        <div className="alert alert-danger">
          <p>Something wrong</p>
        </div>
        <div>
        {this.state.validationErros.map((item, key) =>
          // <ValidationSnackBar type={'error'} text={"Hellooooo"} key={key}></ValidationSnackBar>
          <div className="badge badge-danger m-1" key={key}>
            {item[0]}
          </div>
        )}
        </div>
      </div>
    }

    return (
      <div className='container'>

        <form noValidate autoComplete="off">

          <div className="row">
            <div className="col-12">
              <h2>Add User</h2>
            </div>
          </div>

          {errorView}

          <div className="row">

            <TextField
              id="firstname"
              label="First Name"
              className='col-12 col-sm-6'
              value={this.state.fname}
              onChange={this.handleChange('first_name')}
              margin="normal"
            />

            <TextField
              id="lastname"
              label="Last Name"
              className='col-12 col-sm-6'
              value={this.state.lname}
              onChange={this.handleChange('last_name')}
              margin="normal"
            />

            <TextField
              id="email"
              label="Email"
              className='col-12 col-sm-4'
              value={this.state.email}
              onChange={this.handleChange('email')}
              margin="normal"
            />

            <TextField
              id="mobile"
              label="Mobile"
              className='col-12 col-sm-4'
              value={this.state.mobile}
              onChange={this.handleChange('mobile')}
              margin="normal"
            />

            <TextField
              id="staffid"
              label="Staff ID"
              className='col-12 col-sm-4'
              value={this.state.staffid}
              onChange={this.handleChange('staff_id')}
              margin="normal"
            />

            <TextField
              id="standard-password-input"
              label="Password"
              className='col-12 col-sm-6'
              type="password"
              autoComplete="current-password"
              onChange={this.handleChange('password')}
              margin="normal"
            />

            <TextField
              id="standard-password-input"
              label="Confirm Password"
              className='col-12 col-sm-6'
              type="password"
              autoComplete="current-password"
              onChange={this.handleChange('confirm_password')}
              margin="normal"
            />

          </div>

        <Button variant="contained" size="small" className="mt-5 float-right" onClick={this.submitForm} >
            <SaveIcon className="" />
            Save
        </Button>
        </form>
      </div>
    );
  }
}

export default AddUser;