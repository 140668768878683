import React from 'react';

const ErrorAlert = (props) => {
    return(
        <div>
            {/* <div className="alert alert-danger">
                <p>Something wrong</p>
            </div> */}
            <div>
                {props.errors.map((item, key) =>
                    <div className="badge badge-danger m-1" key={key}>
                        {item[0]}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ErrorAlert;